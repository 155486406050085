
import { mapGetters } from 'vuex'
export default {
  computed:{
    ...mapGetters({
      record: 'facilities/view/record'
    })
  },
  created(){
  }

}
