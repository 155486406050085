
import DeleteEdit from "@/modules/services/components/delete-edit.vue";
import ExampleCaption from "@/modules/facilities/components/facility-view-example-caption.vue";
import DifferentCaption from "@/modules/facilities/components/facility-view-different-caption.vue";
import i18n from "@/vueI18n";
import { mapActions, mapGetters } from "vuex";
import { VueAgile } from "vue-agile";
import moment from 'moment'
import FsLightbox from "fslightbox-vue";


export default {
  data() {
    return {
      drugDealURL: "https://drug-deal-web-app.web.app/facilities/",
      id: this.$route.params.id,
      property: null,
      cityId: null,
      regionId: null,
      // listedFor:null,
      // city:null,
      // region: null,
      // type:null,
      // currentUser:'null',
      openImage: false,
      imagesDetails: [],
      images: [],
      // user:null,
      autoplay: true,
      slide: 1,
      datePresentation: null,

      // vue agile carousel
      asNavFor1: [],
      asNavFor2: [],
      options1: {
        dots: false,
        fade: true,
        navButtons: false,
      },

      options2: {
        autoplay: false,
        centerMode: true,
        dots: false,
        navButtons: false,
        // slidesToShow: 0,
        responsive: [
          {
            breakpoint: 600,
            settings: {
              // slidesToShow: 4,
              // slidesToShow: this.record ? Object.keys(this.record.images).length : 0,
            },
          },

          {
            breakpoint: 1000,
            settings: {
              navButtons: true,
            },
          },
        ],
      },
      toggler: false,
      ExampleCaption: ExampleCaption,
      DifferentCaption: DifferentCaption,
      modalImages: [],
      captionArray: [],
      slideIndex: 1,
      // slide: 1
      // slides: [
      //   'https://images.unsplash.com/photo-1453831362806-3d5577f014a4?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600&fit=max&ixid=eyJhcHBfaWQiOjE0NTg5fQ',
      //   'https://images.unsplash.com/photo-1496412705862-e0088f16f791?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600&fit=max&ixid=eyJhcHBfaWQiOjE0NTg5fQ',
      //   'https://images.unsplash.com/photo-1506354666786-959d6d497f1a?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600&fit=max&ixid=eyJhcHBfaWQiOjE0NTg5fQ',
      //   'https://images.unsplash.com/photo-1455619452474-d2be8b1e70cd?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600&fit=max&ixid=eyJhcHBfaWQiOjE0NTg5fQ',
      //   'https://images.unsplash.com/photo-1504674900247-0877df9cc836?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600&fit=max&ixid=eyJhcHBfaWQiOjE0NTg5fQ',
      // 'https://images.unsplash.com/photo-1472926373053-51b220987527?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600&fit=max&ixid=eyJhcHBfaWQiOjE0NTg5fQ',
      // 'https://images.unsplash.com/photo-1497534547324-0ebb3f052e88?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600&fit=max&ixid=eyJhcHBfaWQiOjE0NTg5fQ'
      // ]

      // viewed: false
    };
  },
  components: {
    [DeleteEdit.name]: DeleteEdit,
    agile: VueAgile,
     FsLightbox,
    //  ExampleCaption,
    //  DifferentCaption 
    // Hooper,
    // Slide,
    // HooperPagination,
    // HooperNavigation
  },
  computed: {
    lang(){
      // debugger    
      return i18n.locale
    },


    ...mapGetters({
      is_screen_xs: "layout/is_screen_xs",
      currentUser: "auth/currentUser",
      record: "facilities/view/record",
      user: "facilities/view/user",
      city: "facilities/view/city",
      region: "facilities/view/region",
      facilityListedFor: "facilities/view/facilityListedFor",
      facilityType: "facilities/view/facilityType",
      // jobPosition: 'jobs/view/jobPosition',
      // workplace:'jobs/view/workplace'
    }),
    // isRTL() {
    //     return localStorage.getItem('language') == 'ar'
    // },
    isRTL() {
      return i18n.locale == "ar";
    },
    isScreenXs() {
      return (
        this.$q.screen.lt.md &&
        this.$q.screen.lt.lg &&
        this.$q.screen.lt.xl &&
        this.$q.screen.lt.sm
      );
    },
  },
  watch: {
    $route(to) {
      // debugger
      this.findRecord(to.params.id)
    },
    lang(){
      document.title =
        this.i18n('app.title') +
        ' | ' +
        this.i18n('drugDeal.services.facilities') 
    },
    async record(newvalue) {
      if (this.record) {
        if (Object.keys(this.record.images).length > 0) {
          this.slide = this.record.images[Object.keys(this.record.images)[0]];

          const imagesNumber = Object.values(this.record.images).length
          this.captionArray = new Array(imagesNumber)
          this.captionArray.fill(this.ExampleCaption)

          this.options2.slidesToShow = imagesNumber
          
        }
        this.datePresentation = this.datePresenter(newvalue.createdAt);
        if (this.record.viewers) {
          if (!this.record.viewers.hasOwnProperty(this.currentUser.id)) {
            //   debugger
            this.record.viewers[this.currentUser.id] = true;
            this.record.createdAt = new Date(
              this.record.createdAt.seconds * 1000
            );
            this.record.updatedAt = new Date(
              this.record.updatedAt.seconds * 1000
            );

            delete this.record.id;
            this.doUpdate({ id: this.recordId, values: this.record });
          }
        } else {
          this.record.viewers = {};
          this.record.viewers[this.currentUser.id] = true;
          this.record.createdAt = new Date(
            this.record.createdAt.seconds * 1000
          );
          this.record.updatedAt = new Date(
            this.record.updatedAt.seconds * 1000
          );
          delete this.record.id;
          this.doUpdate({ id: this.recordId, values: this.record });
        }
      }
    },
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args);
    },
    doGoToMyFacilities() {
      this.$router.push("/my-facilities");
    },
    doGoToFacilities() {
      this.$router.push("/facilities");
    },
    ...mapActions({
      setHeaderBg: "layout/setHeaderBg",
      setNavbar: "layout/setNavbar",
      setFooter: "layout/setFooter",
      doUpdate: "facilities/form/doUpdate",
      doFind: "facilities/view/doFind",
      resetRecord: "facilities/view/resetRecord",
      doFindCity: "facilities/view/doFindCity",
      doFindFacilityListedFor: "facilities/view/doFindFacilityListedFor",
      doFindFacilityType: "facilities/view/doFindFacilityType",
    }),
    datePresenter(date) {
      // let dateUpdated = new Date(date.seconds * 1000).toString().slice(0, 15);
      // // let dateUpdated = date.toString().slice(0,15)
      // return dateUpdated;
      const language = localStorage.getItem('language')
      return moment(new Date(date.seconds * 1000)).locale(language).format('DD-MM-yyyy hh:mm a')
    },
    recordDetailsPresenter(name) {
      if (this.isRTL) {
        return name.ar;
      } else {
        return name.en;
      }
    },
    async findRecord(id){
      this.recordId = id;
      await this.resetRecord();
      await this.doFind({
        id
      });
    },   
    // rotate(arr, count) {
    //   // return [...arr.slice(count, arr.length), ...arr.slice(0, count)];
    //   console.log(arr[count]);
    //   const removedElement = arr[count]
    //   // console.log(arr.filter(el => el != removedElement));
    //   // console.log(arr.splice(count, 1));
    //   console.log(arr.filter((ele) => ele != removedElement))
    //   // debugger
    //   // return arr.concat(removedElement, ...arr.filter(el => el != removedElement))
    //   return [removedElement, ...arr.filter((ele) => ele != removedElement)]  
    //   // return [arr[count], ...arr.filter(el => el != removedElement) ]
    // },
    openImageModal(index){
      // this.modalImages = []
      // this.modalImages = this.rotate(Object.values(this.record.images), index)
      // console.log(this.modalImages);
      this.toggler = !this.toggler
      this.slideIndex = index
    }
  },
  async mounted() {
    this.asNavFor1.push(this.$refs.thumbnails);
    this.asNavFor2.push(this.$refs.main);
    this.setHeaderBg("bg-shadow");
    this.setNavbar("services");
    this.setFooter(false);    
  },
  async created() {
    document.title =
                this.i18n('app.title') +
                ' | ' +
                this.i18n('drugDeal.services.facilities') 
    // this.recordId = this.$route.params.id;
    // await this.resetRecord();
    // await this.doFind({
    //   id: this.$route.params.id,
    // });
    await this.findRecord(this.$route.params.id)
    
    


  },
};
